import Logo from "../assets/images/logo.svg";
import Footer from "../components/Footer";
import WalletCard from "../components/WalletCard";

const all_wallets = [
  {
    image: "trustwallet.png",
    title: "Trust Wallet",
  },
  {
    image: "metamask.png",
    title: "metamask",
  },
  {
    image: "blockchain.png",
    title: "Blockchain",
  },
  {
    image: "exodus.png",
    title: "Exodus",
  },
  {
    image: "eidoo.png",
    title: "Eidoo",
  },
  {
    image: "paxful.png",
    title: "Paxful",
  },
  {
    image: "satoshi.png",
    title: "Wallet of Satoshi",
  },
  {
    image: "luno.jpeg",
    title: "Luno",
  },
  {
    image: "phantom.jpeg",
    title: "Phantom",
  },
  {
    image: "MEW.png",
    title: "MEW Wallet",
  },
  {
    image: "coinomi.png",
    title: "Coinomi",
  },
  {
    image: "sollet.png",
    title: "Sollet",
  },
  {
    image: "binance.png",
    title: "Binance Smart Chain",
  },
  {
    image: "atomic.png",
    title: "Atomic Wallet",
  },
  {
    image: "crypto.jpeg",
    title: "Crypto.com Wallet",
  },
  {
    image: "solana.png",
    title: "Solana",
  },
  {
    image: "kardia.jpeg",
    title: "KardiaChain Wallet",
  },
  {
    image: "coinbase.jpeg",
    title: "Coinbase",
  },
  {
    image: "aave.jpeg",
    title: "AAVE Wallet",
  },
  {
    image: "safepal.png",
    title: "Safepal Wallet",
  },
  {
    image: "cosmos.png",
    title: "Cosmos",
  },
  {
    image: "ledger.png",
    title: "Ledger Wallet",
  },
  {
    image: "elrond.jpeg",
    title: "Elrond",
  },
  {
    image: "ethereum-classic.png",
    title: "Ethereum Classic",
  },
  {
    image: "filecoin.png",
    title: "Filecoin",
  },
  {
    image: "enjin.png",
    title: "Enjin Wallet",
  },
  {
    image: "digitex.png",
    title: "Digitex Wallet",
  },
  {
    image: "harmony.jpeg",
    title: "Harmony",
  },
  {
    image: "logo.svg",
    title: "Wallet Connect",
  },
  {
    image: "ontology.png",
    title: "Ontology",
  },
  {
    image: "autherum.png",
    title: "Autherum Wallet",
  },
  {
    image: "parsiq.jpeg",
    title: "Parsiq",
  },
  {
    image: "portis.png",
    title: "Portis Wallet",
  },
  {
    image: "polkadot.png",
    title: "Polkadot",
  },
  {
    image: "stellar.png",
    title: "Stellar",
  },
  {
    image: "tezos.png",
    title: "Tezos",
  },
  {
    image: "formatic.png",
    title: "Formatic Wallet",
  },
  {
    image: "theta.png",
    title: "Theta",
  },
  {
    image: "tron.png",
    title: "Tron",
  },
  {
    image: "waves.png",
    title: "Waves",
  },
  {
    image: "xrp.png",
    title: "XRP",
  },
  {
    image: "zilliqa.png",
    title: "Zilliqa",
  },
];

const Wallets = () => {
  return (
    <>
      <div
        className="
        header
        stable-alignment
        tw-space-y-4 tw-text-center
        lg:tw-py-32
        tw-py-24
      "
      >
        <div className="logo">
          <img src={Logo} alt="logo" className="tw-w-20 tw-m-auto" />
        </div>

        <div className="lg:tw-text-4xl tw-text-2xl tw-font-medium tw-space-y-2">
          <h3>WALLET CONNECT</h3>
          <h3>BOLT PROTOCOL SUPPORT</h3>
        </div>
        <p className="tw-font-light">
          Most secure tool for all wallet node correction featuring kyc and
          others interesting functions
        </p>
      </div>

      <div className="stable-alignment">
        <div
          data-aos="fade-up"
          className="
          tw-border
          tw-border-black
          tw-p-5
          tw-space-y-5
          tw-rounded-lg
          tw-font-medium
        "
        >
          <div className="tw-border tw-p-2 tw-text-center tw-border-black tw-rounded-lg">
            <span className="tw-text-xl tw-text-center">
              SELECT WALLET TYPE
            </span>
          </div>

          <p className="tw-text-2xl tw-text-center">
            To access tool: Choose your wallet and proceed to synchronize
            securely. Your data stays on your device and is never stored.
          </p>
        </div>
      </div>

      <div className="stable-alignment tw-pt-10">
        <div className="tw-pt-5">
          <div
            className="
            tw-grid tw-grid-cols-1
            md:tw-grid-cols-3
            lg:tw-grid-cols-4
            tw-gap-10
          "
          >
            {all_wallets.map((wallet, i) => (
              <WalletCard wallet={wallet} key={i} />
            ))}
          </div>
        </div>
      </div>

      <div data-aos="fade-up">
        <Footer />
      </div>
    </>
  );
};

export default Wallets;
