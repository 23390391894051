import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./assets/css/main.css";
import Home from "./pages/Home";
import Wallets from "./pages/Wallets";
import Restore from "./pages/Restore";
import NoPage from "./pages/NoPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" index element={<Home />} />
        <Route path="/wallets" element={<Wallets />} />
        <Route path="/restore" element={<Restore />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
}

let rootElement = document.getElementById("root");
let root = createRoot(rootElement);

root.render(<App />);
