import "../assets/css/loader.css";

const Loader = ({ loading }) => (
  <>
    {loading ? (
      <div>
        <div className="overlay">
          <div className="loader tw-grid tw-place-content-center tw-h-screen">
            <div className="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    ) : null}
  </>
);

export default Loader;
