import { useNavigate } from "react-router-dom";

const WalletCard = ({ wallet }) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        onClick={() => navigate(`/restore?wallet=${wallet.title}`)}
        className="
        card
        tw-bg-white
        tw-shadow-lg
        tw-p-4
        tw-py-8
        tw-space-y-5
        tw-rounded-lg
        tw-cursor-pointer
      "
      >
        <div className="wallet-image tw-w-[80px] tw-h-[80px] tw-rounded-full tw-m-auto">
          <img
            src={require("../assets/images/" + wallet.image)}
            alt={wallet.title}
            className="tw-w-full tw-h-full tw-object-cover "
          />
        </div>

        <p className="tw-text-center tw-font-bold tw-uppercase">
          {wallet.title}
        </p>
      </div>
    </>
  );
};

export default WalletCard;
