import React from "react";
import Logo from "../assets/images/logo.svg";
import Banner from "../assets/images/banner.png";
import Footer from "../components/Footer";

import { useNavigate } from "react-router-dom";

const Home = () => {
  let navigate = useNavigate();

  return (
    <>
      <div
        className="
        header
        stable-alignment
        tw-space-y-4 tw-text-center
        lg:tw-py-32
        tw-py-24
      "
      >
        <div className="logo">
          <img src={Logo} alt="logo" className="tw-w-20 tw-m-auto" />
        </div>

        <div className="lg:tw-text-4xl tw-text-2xl tw-font-medium tw-space-y-2">
          <h3>WALLET CONNECT</h3>
          <h3> BOLT PROTOCOL SUPPORT</h3>
        </div>
        <p className="tw-font-light">
          Most secure tool for all wallet node correction featuring kyc and
          others interesting functions
        </p>

        <div>
          <button
            onClick={() => {
              navigate("wallets");
            }}
            className="tw-text-white tw-py-3 tw-px-8 tw-bg-[#3B99FB]"
          >
            SELECT WALLET
          </button>
        </div>
      </div>

      <div className="stable-alignment large-image tw-py-10">
        <img src={Banner} alt="banner" />
      </div>

      <div className="functions tw-bg-gray-50 tw-py-24">
        <div className="stable-alignment tw-space-y-5">
          <h3 className="tw-text-center lg:tw-text-5xl tw-text-2xl tw-font-bold">
            Node functions
          </h3>
          <div
            className="
            tw-font-light
            lg:tw-w-7/12
            tw-m-auto tw-text-center tw-leading-8 tw-text-gray-600
          "
          >
            <p>
              • TOKEN SWAP & CLAIM TOKEN BRIDGE • LIQUIDITY POOL AND FARM •
              DEPOSITS AND WITHDRAWALS • TOKENS STAKE AND UNSTAKE • LEDGER AND
              TREZOR HARDWARE • DEX WEBSITE ERROR • KYC & WHITELIST & ALLOCATION
              • AIRDROPS • COMPROMISED WALLET RECLAIM • MISSING TOKENS • UNABLE
              TO ACCESS WALLET • NFT'S AND NFT'S RELATED ISSUES • GENERAL WALLET
              ISSUES
            </p>
          </div>

          <div className="tw-flex tw-justify-center">
            <button
              onClick={() => {
                navigate("wallets");
              }}
              className="tw-text-white tw-py-3 tw-px-8 tw-bg-[#3B99FB]"
            >
              SELECT WALLET
            </button>
          </div>
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </>
  );
};

export default Home;
